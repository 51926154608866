import { TextProps } from "./types"

export const getWorksPaddingV2 = (str, scale = 1, unit = "px") => {
  if (!str) return
  const scaleRate = 1
  // const scaleRate = getMobileWidth() / 375
  const padding = String(str || "")
  const paddingList = padding?.split(" ") || []
  /** 根据屏幕宽度和375的比例缩放，然后返回padding */
  const getPadding = (val) => {
    return `${(+val * scaleRate * scale).toFixed(0)}${unit}`
  }
  paddingList.forEach((item, index) => {
    paddingList[index] = getPadding(item.replace(/px/gi, ""))
  })
  return paddingList.join(" ")
}

export const blockStyleFilter = (style: React.CSSProperties, scale = 1): React.CSSProperties => {
  return {
    ...style,
    padding: getWorksPaddingV2(style?.padding),
    margin: getWorksPaddingV2(style?.margin),
    borderRadius: getWorksPaddingV2(style?.borderRadius),
    borderWidth: getWorksPaddingV2(style?.borderWidth),
    outlineWidth: getWorksPaddingV2(style?.outlineWidth),
  }
}

interface GetTextContentStyleParams {
  inputStyle: React.CSSProperties & TextProps
  inViewer: boolean
  isH5: boolean
}

/**
 * 不包含字体的样式
 */
export const getTextContentStyle = (params: GetTextContentStyleParams) => {
  const { inputStyle, inViewer, isH5 } = params
  const {
    originBoxInfo,
    fontUrl,
    rawData,
    fontWeight,
    planText,
    colorRaw,
    color,
    textDecoration,
    lineHeight = 1.5,
    letterSpacing = 0,
    fontSize,
    textDirection,
    // fontFamily,
    listStyle,
    writingMode,
    effects,
    hasChangedWidth,
    text,
    background,
    width,
    height,
    transform,
    padding,
    // margin,
    ...otherStyle
  } = inputStyle

  const style: React.CSSProperties = {
    ...otherStyle,
    background,
    lineHeight,
    fontWeight,
    textDecoration: Array.isArray(textDecoration) ? textDecoration?.join(" ") : textDecoration,
    writingMode,
    color,
    width,
    height,
    letterSpacing: `${(letterSpacing / 100) * fontSize}px`,
    fontSize: Number.isNaN(fontSize) ? "inherit" : `${fontSize}px`,
    textAlign: textDirection,
    transformOrigin: `top left`,
    overflow: inViewer && isH5 ? "hidden" : "unset",
    transform,
  }

  return style
}
