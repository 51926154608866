import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import React from "react"
import clas from 'classnames'
import { EventEmitter, DebounceClass, isIOS, mergeDeep, set, sleep } from "@mk/utils"
import { API, getUid, request } from "@mk/services"
import { batchGetFontCropUrlManager, loadFontAction, waitFontLoad } from "./load-font"
import { TextProps } from "../shared/types"
import FillEffects from "./FillEffects"
import { getScaleRateForZoom, getTextCompId, getTextEditAreaId, getTextRootId, listPaddingRate } from "../shared/const"
import { getTextScale } from "../shared/common-util"
import { blockStyleFilter, getTextContentStyle } from "../shared/utils"
import TextBgArea from "./TextBgArea"

import "./index.scss"

const debounce = new DebounceClass()

const defaultFormValues = {
  textDirection: 'center',
  letterSpacing: 0,
  lineHeight: '1.6',
}

interface State {
  loadingFont: boolean
  currFontFamily: string
}

const textScope = document.querySelector<HTMLDivElement>('#id-canvas')

/**
 * 富文本字体下载流程
 * 1. 字体加载
 * 2. 获取字体的容器 DOM 节点，获取容器宽高
 * 3. 调用 didMount，didLoaded 生命周期回调
 * 4. 字体切换，重回步骤 1
 * @param props 
 * @returns 
 */
class Text extends React.Component<PlatformCompProps<TextProps>, State> {
  textContainerRef = React.createRef<HTMLDivElement>()

  constructor(props: PlatformCompProps<TextProps>) {
    super(props)
    this.state = {
      loadingFont: true,
      currFontFamily: props.controledValues.fontFamily,
    }
  }

  async componentDidMount() {
    const { controledValues, lifecycle, editorSDK, worksType, viewerSDK, canvaInfo } = this.props
    Object.assign(window, {
      __canvaInfo: canvaInfo
    })
    const { didLoaded } = lifecycle

    if (this.checkIsInTextScope() && editorSDK && this.textContainerRef.current) {
      /** 在编辑器主画布中 */
      this.textContainerRef.current.addEventListener('dblclick', this.handleDbClick)
      this.onWidgetDidMount()
    }

    if (viewerSDK && /h5/i.test(worksType || '')) {
      /** 如果是h5，不等裁剪字体加载 */
      didLoaded?.()
    }

    const isPoster = /poster/i.test(worksType || '')

    const finishLoadFont = async () => {
      await sleep(200)
      didLoaded?.()
    }

    try {
      if (editorSDK) {
        // await this.loadCropFont()
        await this.loadFullFontFamily()
        // setTimeout(() => {
        //   this.loadFullFontFamily().catch(() => {
        //     console.error('load full font error')
        //   })
        // }, 200)
        return
      } else {
        /** viewer 内 */
        if (isPoster && viewerSDK) {
          /** 海报加载全量字体 */
          await this.loadFullFontFamily()
          await finishLoadFont()
          return
        }
        /** h5 加载裁剪字体 */
        await this.loadCropFont()
      }
    } catch (err) {
      await finishLoadFont()
    }
  }

  componentDidUpdate(prevProps: PlatformCompProps<TextProps>) {
    // this.textRadianChange()
    if (!this.checkIsInTextScope()) return

    const { controledValues, editorSDK } = this.props
    if (editorSDK && controledValues.fontFamily !== prevProps.controledValues.fontFamily && !this.state.loadingFont) {
      /** 编辑器内更换了字体，加载字体 */
      this.loadFullFontFamily()
    }
    this.resetListPadding()
  }

  componentWillUnmount() {
    const { id, editorSDK } = this.props
    window.sessionStorage.setItem(`editTextCache_${id}`, 'false')

    const inTextScope = this.checkIsInTextScope()
    if (inTextScope && editorSDK && this.textContainerRef.current) {
      this.textContainerRef.current.removeEventListener('dblclick', this.handleDbClick)
    }
  }

  handleDbClick = () => {
    const { id } = this.props
    window.sessionStorage.setItem(`editTextCache_${id}`, 'true')
    EventEmitter.emit(`enterTextEditingMode_${id}`, {})
  }

  _getScaleRateForZoom() {
    const { controledValues: { flowMode = false } } = this.props
    if (flowMode) {
      return 1
    }
    return getScaleRateForZoom(this.props.controledValues.fontSize || 0, this.props.canvaInfo.scaleRate || 1)
  }

  getFontColor = () => {
    const { controledValues } = this.props
    const { colorRaw, color, effects = {} } = controledValues
    if (this.useEffectFillForColor()) {
      return (effects.fill?.[0] as any)?.hex || effects.fill?.[0].value
    } else {
      return color || colorRaw?.value || colorRaw?.hex || '#000'
    }
  }

  useEffectFillForColor = () => {
    const { controledValues, id } = this.props
    const { effects } = controledValues
    return !effects?.shadow && !effects?.stroke && effects?.fill?.length === 1 && effects?.fill[0].type === 'color'
  }

  getTContentStyle() {
    const { controledValues, viewerSDK, worksType, id } = this.props
    const {
      // margin, padding, 
      borderRadius, borderWidth, background, borderColor, borderStyle, border, boxShadow,
      ...other
    } = controledValues
    const { currFontFamily } = this.state
    const _cropFontFamily = currFontFamily === 'default' ? undefined : currFontFamily

    return blockStyleFilter(getTextContentStyle({
      inputStyle: {
        ...other,
        fontFamily: `'${_cropFontFamily}'`,
        width: this.getWidth(),
        height: this.getHeight(),
        color: this.getFontColor(),
        fontSize: this.getFontSize(),
        transform: `scale(${1 / this._getScaleRateForZoom()})`
      },
      inViewer: !!viewerSDK,
      isH5: /h5/i.test(worksType || ''),
    }))
  }

  getOriginBoxInfo = () => {
    const { controledValues: { originBoxInfo, flowMode = false }, id } = this.props
    if (flowMode) {
      return null
    }

    return originBoxInfo
  }

  getContainerStyle() {
    const { controledValues: { originBoxInfo, flowMode = false }, viewerSDK } = this.props
    const selfOriginBoxInfo = this.getOriginBoxInfo()

    const style: React.CSSProperties = {
      opacity: selfOriginBoxInfo || flowMode ? 1 : 0,
      width: selfOriginBoxInfo?.width || 'auto',
      height: selfOriginBoxInfo?.height || 'auto',
      position: 'relative',
      // overflow: viewerSDK ? 'hidden' : undefined
    }

    return style
  }

  checkIsInTextScope = () => {
    const { editorSDK } = this.props
    return !editorSDK || textScope?.contains?.(this.textContainerRef.current)
  }

  resetListPadding = () => {
    const { id, controledValues: { writingMode } } = this.props
    if (!this.textContainerRef.current) return
    const textListDOMs = this.textContainerRef.current.querySelectorAll<HTMLDivElement>(`#${getTextCompId(id)} .text-content ol,#${getTextCompId(id)} .text-content ul`)
    if (textListDOMs) {
      for (const textListDOM of textListDOMs) {
        if (writingMode === 'vertical-rl') {
          textListDOM.style.paddingTop = `${this.getFontSize() * listPaddingRate}px`
          textListDOM.style.paddingLeft = `0`
        } else {
          textListDOM.style.paddingTop = `0`
          textListDOM.style.paddingLeft = `${this.getFontSize() * listPaddingRate}px`
        }
      }
    }
  }

  loadFullFontFamily = async () => {
    const { fontFamily, fontUrl } = this.props.controledValues
    await this.loadFont({ fontFamily, fontUrl })
  }

  /**
   * @deprecated
   * 已废弃
   * 通过接口获取字体裁剪地址
   */
  fontResourceFilter = async ({
    fontFamily,
    fontUrl,
  }, isLoadCrop = true): Promise<{ fontFamily, fontUrl }> => {
    // let _fontUrl = fontUrl
    // if (!_fontUrl) return { fontFamily: null, fontUrl: null }
    // if (/MAKAInternal/.test(window.navigator.userAgent)) {
    //   _fontUrl = fontUrl.replace(/https?:\/\/font\.maka\.im/, internalUrl)
    // }
    const result = {
      fontFamily,
      fontUrl,
    }
    const { controledValues, viewerSDK, editorSDK } = this.props
    // const { originContent } = controledValues
    const { text } = controledValues
    if (!text) return { fontFamily: null, fontUrl: null }
    // eslint-disable-next-line no-irregular-whitespace
    // const planText = `${text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;?/g, ' ').replace('    ', ' ')}　`
    // eslint-disable-next-line no-irregular-whitespace
    const planText = `${text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;?/g, ' ')}　`
    // const planText = `${text.replace(/<[^>]*>?/gm, '')} `
    // eslint-disable-next-line no-irregular-whitespace
    // const planText = `${text.replace(/<[^>]*>?/gm, '')} 　`
    if (isLoadCrop) {
      // if (fontFamily === 'Alibaba-PuHuiTi-Regular' && (['添加标题', '添加副标题', '添加正文'].includes(originContent))) {
      //   result.fontUrl = 'https://font.maka.im/maka_font/mk_editor_v7/3b85d44d5bc2f5b342d8a40fc88050ee.ttf'
      // } else {
      // }
      try {
        const params = {
          "uid": getUid() || viewerSDK?.workInfo?.getUID?.() || 'none',
          "fontFamily": fontFamily,
          "content": planText,
          "pageId": viewerSDK?.workInfo?.getWorksID?.() || '__temp__'
        }

        const res = await request.post(`${API('根域名')}/mk-fe-node/get-font/font`, params)
        if (res?.data?.url) {
          result.fontUrl = res?.data?.url
        }
      } catch (e) {
        console.error(e)
      }
      return result
    }
    return result
  }

  /**
   * 通过接口获取字体裁剪地址
   */
  fontResourceFilterV2 = ({
    fontFamily,
    fontUrl,
  }): Promise<{ fontFamily, fontUrl }> => {
    return new Promise((resolve, reject) => {
      const { id } = this.props
      // let _fontUrl = fontUrl
      // if (!_fontUrl) {
      //   resolve({ fontFamily: null, fontUrl: null })
      //   return
      // }
      // if (/MAKAInternal/.test(window.navigator.userAgent)) {
      //   _fontUrl = fontUrl.replace(/https?:\/\/font\.maka\.im/, internalUrl)
      // }
      const result = {
        fontFamily,
        fontUrl,
      }
      const { controledValues, viewerSDK, editorSDK } = this.props
      const { text } = controledValues
      if (!text) {
        resolve({ fontFamily: null, fontUrl: null })
        return
      }
      // eslint-disable-next-line no-irregular-whitespace
      const planText = `${text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;?/g, ' ')}　`
      if (!batchGetFontCropUrlManager.options?.uid) {
        batchGetFontCropUrlManager.setOptions({
          uid: getUid() || viewerSDK?.workInfo?.getUID?.() || 'none',
          pageId: viewerSDK?.workInfo?.getWorksID?.() || '__temp__'
        })
      }

      debounce.exec(() => {
        console.log(id, 'load font timeout')
        resolve(result)
      }, 5000)

      const handleSuccess = (resData) => {
        // console.log('itemId', itemId)
        const currData = resData[id]
        // console.log('currData', id, currData)
        // console.count('success')
        debounce.cancel()
        result.fontFamily = currData?.fontFamily || fontFamily
        result.fontUrl = currData?.url || fontUrl
        resolve(result)
        // console.count(id)
        // batchGetFontCropUrlManager.rm('success', handleSuccess)
      }

      batchGetFontCropUrlManager.once('success', handleSuccess)
      batchGetFontCropUrlManager.addItem({
        fontFamily,
        content: planText,
        id
      })
    })
  }

  protected loadCropFont = async () => {
    const {
      fontFamily,
      fontUrl
    } = this.props.controledValues
    /** 判断组件是否在主画布上，而不是在左侧的页面 */
    const inTextScope = this.checkIsInTextScope()
    const { id } = this.props
    if (!inTextScope) {
      /** 如果不在主画布上，则无需响应字体加载 */
      return false
    }
    const fontRes = await this.fontResourceFilterV2({
      fontFamily,
      fontUrl,
    })

    await this.loadFont({
      fontFamily: fontRes.fontFamily,
      fontUrl: fontRes.fontUrl
    })
  }

  private loadFont = async ({ fontFamily, fontUrl }) => {
    if (fontFamily && fontUrl) {
      // this.setLoading(true)
      this.setState({
        loadingFont: true,
      })
      try {
        await loadFontAction({
          fontFamily,
          fontUrl,
        })
        this.setState({
          loadingFont: false,
          currFontFamily: fontFamily,
        })
      } catch (err) {
        console.error(`加载字体失败：${fontFamily} ${fontUrl}`, err)
      }
    } else {
      this.setState({
        loadingFont: false,
      })
    }
  }

  /**
   * 兼容文字 1.0 升级到 2.0 color 转换到特效填充的数据
   */
  getDefaultCommitData = (_originBoxInfo) => {
    const { controledValues } = this.props
    const {
      fontFamily, text, textCrop, color,
      // 对于已有文字组件，应该不会自动改宽
      hasChangedWidth = true
    } = controledValues
    const planText = text.replace(/<[^>]*>?/gm, '')
    const commitData = mergeDeep({}, {
      originBoxInfo: _originBoxInfo,
      // 补全字体裁剪信息
      textCrop: textCrop || {
        content: planText,
        fontFamily,
      },
      textScale: 1,
      hasChangedWidth
    }, defaultFormValues, controledValues)
    return commitData
  }

  onWidgetDidMount = async () => {
    // this.editroLoadOver()
    const { controledValues, lifecycle, containerInfo, id, editorSDK } = this.props
    const { originBoxInfo, text, textCrop, fontSize, textScale } = controledValues
    if (!text) return

    const { didMount } = lifecycle

    const textContainerDOM = this.textContainerRef.current
    if (textContainerDOM) {
      const { clientWidth, clientHeight } = textContainerDOM
      const boxInfo = {
        width: containerInfo.width || (clientWidth + 4),
        height: containerInfo.height || clientHeight,
      }

      const isOriginBoxInfoDiffContainer = !!originBoxInfo && originBoxInfo.width !== containerInfo.width

      const _originBoxInfo = {
        width: boxInfo.width,
        height: boxInfo.height,
      }
      const isFirstLoad = isOriginBoxInfoDiffContainer || !originBoxInfo || !textCrop

      const commitData = isFirstLoad ? {
        data: this.getDefaultCommitData(_originBoxInfo)
      } : {
        data: {}
      } as any

      const mountData = Object.assign({}, {
        boxInfo,
      }, commitData)
      didMount?.(mountData)

      if (String(containerInfo.width) !== 'auto' && (!originBoxInfo || isOriginBoxInfoDiffContainer)) {
        /** 
         * 如果内层的宽高不等于外层，则尝试修正
         * 这是第四版修正文字缺陷的手段
         */
        editorSDK?.changeCompAttr(id, {
          originBoxInfo: boxInfo,
          textScale: 1, // 废弃的字段，采用根据不同终端动态计算的缩放比例
          // 修正字体的大小，不在采用 textScale 的缩放方式，而是通过改变 fontSize 来实现
          fontSize: fontSize * (textScale || 1)
        })
      }

      const effect = this.getEffects()
      let replaceEffectToColor = false
      if (effect.fill.length === 1 && effect.fill[0].type === 'color' && !effect.stroke?.length && !effect.shadow?.length) {
        /** 将只有一个的填充特效转化成字体颜色 */
        replaceEffectToColor = true
      }
      if (replaceEffectToColor) {
        /** 
         * 如果内层的宽高不等于外层，则尝试修正
         * 这是第四版修正文字缺陷的手段
         */
        editorSDK?.changeCompAttr(id, {
          effects: {
            fill: []
          },
          color: effect.fill[0].value
        })
      }
    }
  }

  onFillImgLoad = (url: string) => {
    if (this.textContainerRef.current) {
      if (url === '' || url == null) {
        this.textContainerRef.current.style.backgroundImage = ''
        this.textContainerRef.current.style.backgroundClip = ''
        this.textContainerRef.current.style.webkitBackgroundClip = ''
      } else {
        this.textContainerRef.current.style.backgroundImage = `url("${url.replace(/\s/g, encodeURIComponent(' '))}")`
        this.textContainerRef.current.style.backgroundClip = 'text'
        this.textContainerRef.current.style.webkitBackgroundClip = 'text'
      }
    }
  }

  zoomRate = () => {
    const { controledValues, containerInfo } = this.props
    const selfOriginBoxInfo = this.getOriginBoxInfo()
    const textScaleRate = getTextScale(controledValues, containerInfo)
    if (!selfOriginBoxInfo) return textScaleRate
    return textScaleRate / this._getScaleRateForZoom()
  }

  readText = () => {
    const { controledValues, id } = this.props
    const { text = '请输入文字!' } = controledValues
    return text
  }

  isEffects = () => {
    const { controledValues } = this.props
    const { effects } = controledValues
    const isUseEffectFillForColor = this.useEffectFillForColor()
    if (isUseEffectFillForColor) {
      return false
    }
    if (effects) {
      if (Object.values(effects).filter(e => e instanceof Array).some(eff => (eff as Array<any>).length > 0)) {
        return true
      }
      return false
    }
  }

  getTextShadow = () => {
    const { controledValues, canvaInfo: { scaleRate } } = this.props
    const { textShadowConfig = {}, effects } = controledValues
    const rate = this.zoomRate()
    const shadowLength = 6 // 未来开放此参数
    const shadowR = ((textShadowConfig.direction || 0) + 45) / 180 * Math.PI
    const textShadow = textShadowConfig?.enable && `${shadowLength * Math.sin(shadowR)}px ${shadowLength * Math.cos(shadowR)}px ${textShadowConfig.blur}px ${textShadowConfig.color}`
    const textShadows = [textShadow]
    if (effects) {
      const shadows = effects?.shadow || []
      const status = isIOS()
      const shadowStrList = shadows.map(s => {
        if (status) {
          const dx = 1
          const min = 1.5
          const p = scaleRate * rate * this._getScaleRateForZoom() // 由于缩放导致的阴影补偿过大，这里需要把比例换算回正确的
          let x = +s.offsetX
          let y = +s.offsetX
          if (+s.offsetX > 0) {
            x = (+(s.offsetX) + dx) * p < min ? min / p : (+(s.offsetX) + dx)
          } else {
            x = Math.abs((+(s.offsetX) - dx) * p) < min ? (-1 * min / p) : (+(s.offsetX) - dx)
          }
          if (+s.offsetY > 0) {
            y = (+(s.offsetY) + dx) * p < min ? min / p : (+(s.offsetY) + dx)
          } else {
            y = Math.abs((+(s.offsetY) - dx) * p) < min ? (-1 * min / p) : (+(s.offsetY) - dx)
          }
          const result = `${x}px ${y}px ${+s.blur + dx}px ${s.color}`
          return result
        }
        return `${s.offsetX}px ${s.offsetY}px ${s.blur}px ${s.color}`
      })
      textShadows.push(...shadowStrList)
    }
    return textShadows.filter(text => !!text).join(',')
  }

  getTextStroke = () => {
    const { controledValues } = this.props
    const { effects } = controledValues
    if (effects) {
      const strokes = effects?.stroke || []
      const strokeStrList = strokes.map(s => `${s.value}px ${s.color}`)
      return strokeStrList.join(',')
    }
    return ''
  }

  getEffects = () => {
    const { controledValues, id } = this.props
    const { effects } = controledValues
    const newEff = { ...effects, fill: [...effects?.fill || []] }
    return newEff
  }

  renderTextContentWithEffects = () => {
    const { controledValues, } = this.props
    const { padding } = controledValues
    const effects = this.getEffects()
    const effectsTags = [effects.shadow?.length && 'shadow', effects.stroke?.length && 'stroke'].filter(e => !!e)
    // const { currFontFamily } = this.state
    // const _cropFontFamily = currFontFamily === 'default' ? undefined : currFontFamily

    return (
      <>
        {effectsTags.map(tag => {
          const style: React.CSSProperties = {}
          if (tag === 'shadow') {
            style.textShadow = this.getTextShadow()
          } else if (tag === 'stroke') {
            style.WebkitTextStroke = this.getTextStroke()
          }
          const dom = (
            <div
              key={tag}
              className={clas(`text-content text_${tag}`, this.isEffects() && 'fill_content')}
              style={blockStyleFilter({
                // fontFamily: `'${_cropFontFamily}'`, 
                ...style, padding
              })}
              dangerouslySetInnerHTML={{ __html: this.readText() }}></div>
          )
          return dom
        })}
        <div
          className={clas(`text-content independent text_effects`, this.isEffects() && 'fill_content')}
          style={blockStyleFilter({
            textShadow: this.getTextShadow(),
            // fontFamily: `'${_cropFontFamily}'`,
            padding
          })}
          dangerouslySetInnerHTML={{ __html: this.readText() }}></div>
      </>
    )
  }

  renderTextContent = () => {
    const { controledValues, containerInfo } = this.props
    const { padding } = controledValues
    const effects = this.getEffects()
    const effectsTags = [effects.shadow?.length && 'shadow', effects.stroke?.length && 'stroke'].filter(e => !!e)
    const hasEffect = effectsTags.length !== 0
    // if (effectsTags.length !== 0) {
    //   // 有特效
    //   return this.renderTextContentWithEffects()
    // }

    const style = blockStyleFilter({
      textShadow: this.getTextShadow(),
      padding
    })

    return (
      <>
        {
          hasEffect && (
            this.renderTextContentWithEffects()
          )
        }
        <div
          className={clas(
            `text-content independent`,
            hasEffect && 'has_text_effects',
            this.isEffects() && 'fill_content'
          )}
          style={style}
          dangerouslySetInnerHTML={{ __html: this.readText() }}></div>
      </>
    )
  }

  getFontSize = () => {
    const { controledValues, id } = this.props
    const { fontSize } = controledValues
    const selfOriginBoxInfo = this.getOriginBoxInfo()
    if (!selfOriginBoxInfo) {
      return fontSize || 14
    }
    return fontSize * this._getScaleRateForZoom()
  }

  getWidth = () => {
    const selfOriginBoxInfo = this.getOriginBoxInfo()
    if (!selfOriginBoxInfo) return 'auto'
    return selfOriginBoxInfo.width * this._getScaleRateForZoom()
  }

  getHeight = () => {
    const selfOriginBoxInfo = this.getOriginBoxInfo()
    if (!selfOriginBoxInfo) return 'auto'
    return selfOriginBoxInfo.height * this._getScaleRateForZoom()
  }

  render() {
    const { controledValues, id, editorSDK } = this.props
    const {
      listStyle, writingMode, effects,
    } = controledValues

    const listMode = listStyle && listStyle !== 'none'
    const effectList = this.getEffects()

    const isVertical = writingMode === 'vertical-rl'
    const { width, height } = this.textContainerRef?.current?.getBoundingClientRect?.() || {}

    return (
      <div
        id={getTextRootId(id)}
        style={this.getContainerStyle()}
        className="__text_comp_container__">
        <div
          ref={this.textContainerRef}
          id={getTextCompId(id)}
          className={clas(
            '__text_content__',
            listMode && 'list-mode',
            isIOS() && 'iphone',
            isVertical ? 'vertical' : 'horizontal',
            listStyle,
          )}
          style={this.getTContentStyle()}>
          {this.renderTextContent()}
          <FillEffects
            key={JSON.stringify(effectList) + width + height}
            effects={effectList}
            width={width || 'auto'}
            height={height || 'auto'}
            id={id}
            onFillImgLoad={this.onFillImgLoad} />
        </div>
        <div id={getTextEditAreaId(id)}></div>
        <TextBgArea {...this.props} />
      </div>
    )
  }
}

export default Text
