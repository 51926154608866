export const COM_ROOT_PREFIX = 'for_text_'
export const TEXT_COM_PREFIX = '__text__'
export const COM_EDIT_DOM_PREFIX = 'for_editing_'
export const EDITING_DOM_PREFIX = '__editing_text_'
export const EDITOR_CANVA_SCOPE = "id-canvas"

export const isIphone = /Safari|iPhone/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)

/**
 * 文字缩放比例基数准，重要参数
 * 背景：在 iOS safari 上由于 zoom 缩放方案导致文字小于 10px 时字体大小不对的问题
 * 解决方式：所有文字的宽高、字体大小 * 该比例，然后通过 scale(1/比例) 缩放，保证文字必然 > 10px
 */
export const getScaleRateForZoom = (fontSize: number, scale: number) => {
  if (fontSize * scale < 32) {
    if (isIphone) {
      return 32 / (fontSize * scale)
    }
    return 1
  } else {
    return 1
  }
}

/**
 * 列表的缩放比例
 */
export const listPaddingRate = 1.6

export const getTextRootId = (id: string) => {
  return `${COM_ROOT_PREFIX}${id}`
}

export const getTextCompId = (id: string) => {
  return `${TEXT_COM_PREFIX}${id}`
}

export const getTextEditAreaId = (id: string) => {
  return `${COM_EDIT_DOM_PREFIX}${id}`
}

export const getTextEditingDOMId = (id: string) => {
  return `${EDITING_DOM_PREFIX}${id}`
}

export const getTextRootContainer = (id: string) => {
  return document.querySelector(`#${EDITOR_CANVA_SCOPE} #${getTextEditAreaId(id)}`)
}

export const setTextContentStyle = (id, style) => {
  const textContainerDOM = getTextContentDOMs(id)

  if (textContainerDOM.length === 0) return

  textContainerDOM.forEach((item) => {
    if (item) Object.assign(item.style, style)
  })
}

export const getTextCompRootDOM = (id: string) => {
  const normalTextContainerDOM = document.querySelector<HTMLElement>(`#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} .__text_content__`)
  return normalTextContainerDOM
}

export const getTextContainerDOM = (id: string) => {
  const normalTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden)`
  )
  const editingTextContainerDOM = document.querySelector<HTMLElement>(`#${EDITOR_CANVA_SCOPE} #${getTextEditingDOMId(id)}`)
  // const isEditing = normalTextContainerDOM?.classList.contains('.hidden')
  const textContainerDOM = editingTextContainerDOM || normalTextContainerDOM
  return textContainerDOM
  // return isEditing ? editingTextContainerDOM : normalTextContainerDOM
}

export const getTextContentDOM = (id) => {
  const effectTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden) .text-content.text_effects`
  )
  const normalTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden) .text-content`
  )
  const editingTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextEditAreaId(id)} .__text_content__:not(.hidden) .text-content`
  )
  const textContainerDOM = effectTextContainerDOM || normalTextContainerDOM || editingTextContainerDOM
  return {
    dom: textContainerDOM,
    editing: !!editingTextContainerDOM
  }
}

export const getTextContentDOMs = (id: string) => {
  const normalTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden) .text-content`
  )
  // const textBgDOM = document.querySelector<HTMLElement>(
  //   `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)}:not(.hidden) #${getTextCompId(id)} .text_bg_container`
  // )
  const effectTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden) .text-content.text_effects`
  )
  const strokeTextContainerDOM = document.querySelector<HTMLElement>(
    `#${EDITOR_CANVA_SCOPE} #${getTextRootId(id)} #${getTextCompId(id)}:not(.hidden) .text-content.text_stroke`
  )
  const editingTextContainerDOM = getEditingTextContentDOM(id)
  const textContainerDOMs = [effectTextContainerDOM, strokeTextContainerDOM, normalTextContainerDOM, editingTextContainerDOM,
    // textBgDOM
  ].filter(i => !!i)
  return textContainerDOMs
}

export const getEditingTextContentDOM = (id) => {
  // 获取 draftjs 的编辑区 DOM 节点
  return document.querySelector<HTMLElement>(`#${EDITOR_CANVA_SCOPE} #${getTextEditingDOMId(id)} [data-contents="true"]`)
}

export const getOperatorDOM = (id) => {
  const dom = document.querySelector<HTMLElement>(`.${id}.canvas_operation_control`)
  return dom
}
