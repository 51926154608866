import { ContentBlock } from 'draft-js'
import React from 'react'

export const fontSizeOpts = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 40, 56, 72, 96, 120, 160, 200, 240, 300, 400]
export const letterSpacingOpts = [0, 12, 24, 40, 72, 96, 120, 160, 200]
export const lineHeightOpts = [0.5, 1.0, 1.2, 1.4, 2.0, 2.5, 3.0]
export const inlineStyles = [
  { label: "B", icon: 'bold', style: "bold", styleProp: 'fontWeight', title: '加粗' },
  { label: "I", icon: 'italic', style: "italic", styleProp: 'fontStyle', title: '斜体' },
  { label: "U", icon: 'underlined', style: "underline", styleProp: 'textDecoration', title: '下划线' },
  { label: "S", icon: 'strikethrough', style: "strikethrough", styleBlock: "line-through", styleProp: 'textDecoration', title: '删除线' },
]

export const blockStyles = [
  { label: "", icon: 'leftaligned', style: "left", title: '居左' },
  { label: "", icon: 'centered', style: "center", title: '居中' },
  { label: "", icon: 'rightaligned', style: "right", title: '居右' },
  { label: "", icon: 'justifyaligned', style: "justify", title: '两端' },
]

export const blockClassMapping = {
  "left-align": 'MK_EDITOR-left-align',
  "right-align": 'MK_EDITOR-right-align',
  "text-center": 'MK_EDITOR-center',
}

export function getBlockStyle(block: ContentBlock) {
  const _blockStyle = block.getType()
  // const blockData = block.getData()

  // console.log()
  // const lineHeight = blockData.get('lineHeight') * 10
  // const letterSpacing = blockData.get('letterSpacing')
  // console.log(`_blockStyle`, block)
  if (block.type === "unstyled") return 'text-block'
  const blockCls = blockClassMapping[_blockStyle]

  return blockCls
}

export const customStyleMap = {
  // ...fontSizeStyleMap,
}

// export const inlineStyleMap = {
//   STRIKETHROUGH: 'line-through',
//   // UNDERLINE: 'underline',
// }

export const getFontSizeValue = (fontSizeLabel = '') => {
  return +((fontSizeLabel.replace('fontsize-', '')) || 12)
}

export const getFontFamilyValue = (fontLabel = '') => {
  const res = fontLabel.replace('fontfamily-', '')
  const resJSON = convert2JSON(res)
  return resJSON
}

export const convert2JSON = (target) => {
  let resJSON
  try {
    resJSON = JSON.parse(target)
  } catch (e) {
    // console.log(`字符串转换失败`)
  }
  return resJSON
}

export const convert2HTMLConfig = {
  styleToHTML: (style) => {
    if (/fontsize-/.test(style)) {
      const fontSize = getFontSizeValue(style)
      return <span style={{ fontSize: `${fontSize}px` }} />
    }
    if (/color-/.test(style)) {
      const color = style.replace('color-', '')
      return <span style={{ color: `${color}` }} />
    }
    if (/STRIKETHROUGH/.test(style)) {
      const textDecoration = 'line-through'
      return <span style={{ textDecoration }} />
    }
  },
  blockToHTML: (block) => {
    const { lineHeight, letterSpacing, fontFamily: fontFamilySetting } = block.data
    const fontFamily = convert2JSON(fontFamilySetting)?.fontFamily

    const commonStyle = {
      lineHeight,
      letterSpacing,
      fontFamily,
    }

    // for (const blockStl of blockStyles) {
    //   if (block.text === '') {
    //     // return <br />
    //     return <div className='br'><br /></div>
    //   }
    //   if (block.type === blockStl.style) {
    //     return <div style={commonStyle} className={blockClassMapping[block.type]} />
    //   }
    // }
    if (block.type === 'unstyled' && block.text === '') {
      // return <br />
      return <p><br /></p>
    }
  },
}

const rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`

export const convertFromHTMLConfig = {
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (node.style.fontSize) {
      return currentStyle.add(`fontsize-${node.style.fontSize}`)
    }
    if (node.style.color) {
      return currentStyle.add(`color-${rgba2hex(node.style.color)}`)
    }
    return currentStyle
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity(
        'LINK',
        'MUTABLE',
        { url: node.href }
      )
    }
  },
  textToEntity: (text, createEntity) => {
    const result: any[] = []
    text.replace(/@(\w+)/g, (match, name, offset) => {
      const entityKey = createEntity(
        'AT-MENTION',
        'IMMUTABLE',
        { name }
      )
      result.push({
        entity: entityKey,
        offset,
        length: match.length,
        result: match
      })
    })
    return result
  },
  htmlToBlock: (nodeName, node) => {
    if (nodeName === 'blockquote') {
      return {
        type: 'blockquote',
        data: {}
      }
    }
  }
}
