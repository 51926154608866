import React, { Component } from 'react'
import './index.scss'
import { mergeDeep } from '@mk/utils'
import { FillEffectItem, FillEffectItemGradient, WidgetCommonAttrs } from '@mk/services'
import { calculateGradientCoordinate, rgbaObjToStr, toBlob } from './tools'
// import { md5 } from './md5'

interface ImgDict { img: HTMLImageElement, opacity: number, imgUrl: string }

interface FillItem {
  "hex": string
  "opacity": number
  "type": "color"
  /** 色值 16进制 当有opacity不为100时 值为rgba模式 */
  "value": string
}

interface ShadowItem {
  /** x轴偏离 */
  "offsetX": number
  /** y轴偏离 */
  "offsetY": number
  /** 透明度 */
  "blur": number
  /** 扩散值 */
  "spread": number
  "color": "#000"
}

interface StrokeItem {
  "color": string
  "boxSize": "center" | 'outline' | 'inner'
  /** 粗细 */
  "value": number
}

export interface IEffect {
  "fill": Array<FillItem>
  "stroke": Array<StrokeItem>
  "shadow": Array<ShadowItem>
}

interface Props {
  effects?: WidgetCommonAttrs['effects']
  id: string
  width: number | 'auto'
  height: number | 'auto'

  // effectKey: number
  onFillImgLoad: (url: string) => void
}

interface State {
}

// 计算弧形文字的坐标
// 每个文字单独设置背景
// 1.0 忽略文字弧度情况
class FillEffects extends Component<Props, State>{

  canvasRef?: HTMLCanvasElement

  // width: number

  // height: number


  // timer: number

  constructor(props: Props) {
    super(props)
    this.state = {

    }
    // this.timer = Math.random()
  }

  componentDidMount() {
  }

  loadImg = async (imgUrl: string, opacity: number, width: number, height: number): Promise<ImgDict> => {
    const img = new Image(width, height)
    img.src = imgUrl
    img.crossOrigin = ''
    return new Promise(resolve => {
      img.onload = () => {
        resolve({ img, opacity, imgUrl })
      }
    })
  }

  createImgUrl = async (canvas: HTMLCanvasElement, fill: FillEffectItem[], width: number, height: number): Promise<string | null> => {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    ctx.globalAlpha = 1
    ctx.clearRect(0, 0, width, height)
    let imgResultDict: Record<string, ImgDict> = {}
    const imgEffectItem = fill.filter(f => f.type === 'picture')
    if (imgEffectItem.length > 0) {
      const tasks = imgEffectItem.map(item => this.loadImg(item.value, item.opacity, width, height))
      const imgResults = await Promise.all(tasks)
      imgResultDict = imgResults.reduce<Record<string, ImgDict>>((pre, item) => {
        const { imgUrl } = item
        pre[imgUrl] = item
        return pre
      }, {})
    }
    const newFill = [...fill.reverse()]
    newFill.forEach(item => {
      ctx.globalAlpha = 1
      const { type, value, opacity } = item
      ctx.globalAlpha = (+opacity) / 100
      if (type === 'color' || type == null) {
        ctx.fillStyle = value || item.hex
        ctx.fillRect(0, 0, width, height)
      } else if (type === 'gradient') {
        const newItem = item as FillEffectItemGradient
        const { colors } = newItem
        if (colors.points.length > 0) {
          const [startg, endg] = colors.points
          const { degress = 90 } = colors
          const pointDict = calculateGradientCoordinate(width, height, degress)
          const { x0, y0, x1, y1 } = pointDict
          const grd = ctx.createLinearGradient(x0, y0, x1, y1)
          grd.addColorStop(0, rgbaObjToStr(startg.rgb))
          grd.addColorStop(1, rgbaObjToStr(endg.rgb))
          ctx.fillStyle = grd
          const startP = (startg.position) / 100
          const endP = endg.position / 100
          ctx.fillRect(startP * width, 0, width * endP, height)
          ctx.fillStyle = `rgba(${startg.rgb.r},${startg.rgb.g},${startg.rgb.b},${startg.rgb.a})`
          ctx.fillRect(0, 0, width * startP, height)

          ctx.fillStyle = `rgba(${endg.rgb.r},${endg.rgb.g},${endg.rgb.b},${endg.rgb.a})`
          ctx.fillRect(width * endP, 0, width - width * endP, height)
        }
      } else if (type === 'picture') {
        const { opacity, img } = imgResultDict[value]
        ctx.globalAlpha = (+opacity)
        ctx.drawImage(img, 0, 0, width, height)
      }
    })
    ctx.globalAlpha = 1
    const blobUrl = await toBlob(canvas)
    return blobUrl
  }


  canvasDomLoad = (ref: HTMLCanvasElement | undefined | null) => {
    if (ref != null && this.canvasRef == null) {
      const { width, height } = this.props
      this.canvasRef = ref
      this.checkSizeChange(width, height)
      this.forceUpdate()
    }
  }

  checkSizeChange = (width: number | 'auto', height: number | 'auto') => {
    if (typeof width === 'string' || typeof height === 'string') return
    const { effects } = this.props
    const { fill = [] } = effects || {}
    if (this.canvasRef) {
      if (fill.length === 0) {
        this.props.onFillImgLoad('')
      } else {
        // this.canvasRef.setAttribute('width', `${width}`)
        // this.canvasRef.setAttribute('height', `${height}`)
        // clearTimeout(this.timer)
        // this.timer = window.setTimeout(() => {
        // }, 300)

        this.createImgUrl(this.canvasRef, fill, width, height).then((url) => {
          if (url) {
            this.props.onFillImgLoad(url)
          }
        })
      }
    }
  }

  render() {
    const { width, height } = this.props
    return (
      <canvas className='_mk_effect_canvas'
        width={width}
        height={height}
        ref={this.canvasDomLoad}></canvas>
    )
  }
}

export default FillEffects