import { getWorksDetailStatic } from '@mk/services'
import React from 'react'
import { LoadWidget } from '@mk/utils'
import { TextProps } from '../shared/types'
import { blockStyleFilter } from '../shared/styleHelper'
import { getTextContainerDOM } from '../shared/const'

interface Props {
  controledValues: TextProps
  viewerSDK?
  containerInfo
  canvaInfo
  id
}
export default function TextBgArea(props: Props) {
  const { controledValues, viewerSDK, canvaInfo, containerInfo, id } = props
  const {
    borderRadius, borderWidth, borderColor, borderStyle,
    boxShadow, backgroundGroup, fontSize,
    background
  } = controledValues

  const xOffset = fontSize * 2
  const yOffset = fontSize * 0.5
  const textContainer = getTextContainerDOM(id)
  const textContainerOffset = textContainer?.getBoundingClientRect()
  const containerInfoForBg = textContainerOffset ? {
    width: textContainerOffset.width + xOffset * 2,
    height: textContainerOffset.height + yOffset * 2,
  } : containerInfo

  if (backgroundGroup && backgroundGroup.items?.length > 0) {
    const Background = LoadWidget('MkBg')
    return Background ? (
      <div className="text_bg_container"
        id={`text_bg_container_${id}`}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: -xOffset,
          right: -xOffset,
          top: -yOffset,
          bottom: -yOffset,
        }}
      >
        <Background
          lifecycle={{
            didMount: () => {
              // store.setPageBgReady(navigationIndex)
            },
            didLoaded: () => {

            }
          }}
          containerInfo={containerInfoForBg}
          canvaInfo={canvaInfo}
          controledValues={{
            clipBgLayout: backgroundGroup?.layout || "row",
            clipBgChildren: 11,
            bgData: {
              clipBgSettingV2: backgroundGroup?.items?.map((item) => {
                return {
                  ossPath: item,
                  type: "picture",
                }
              })
            },
          } as any}
        />
      </div>
    ) : null
  } else {
    const worksType = getWorksDetailStatic()?.type

    return (
      <div className="text_bg_area" style={blockStyleFilter({
        boxShadow,
        borderRadius, borderWidth, background, borderColor, borderStyle,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        zIndex: 0,
        // overflow: viewerSDK && /h5/i.test(worksType || '') ? "hidden" : "unset",
      })}></div>
    )
  }
}
