import { ContainerInfo } from "@mk/services"
import { TextProps } from "./types"


/**
 * 获取字体缩放程度
 * @param textProps 
 * @param containerInfo 
 * @returns 
 */
 export const getTextScale = (textProps: TextProps, containerInfo: ContainerInfo) => {
  const { textScale, originBoxInfo, writingMode } = textProps
  if(!originBoxInfo || !containerInfo) return 1
  const isVertical = writingMode === 'vertical-rl'
  return textScale || (isVertical ? containerInfo.width / originBoxInfo.width : containerInfo.height / originBoxInfo.height) || 1
}
