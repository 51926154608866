/**
 * 重要！
 * 编辑器的 mktext form 的共享文件，不能在 comp.js 中引用
 */

import {
  Editor,
  EditorState,
  RichUtils,
  SelectionState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  Modifier,
} from "draft-js"
import htmlToDraft from "html-to-draftjs"
import { extractInlineStyle } from "draftjs-utils"
import { convertToHTML, convertFromHTML } from "draft-convert"
import { isPc } from "@mk/utils"
import { convert2HTMLConfig } from "./draft-setting"
import { getTextContentDOM } from "./const"

export * from "./styleHelper"

export const createEditorState = ({ text = "", rawData = undefined }) => {
  if (!text && !rawData) return EditorState.createEmpty()
  let contentState
  if (rawData) {
    contentState = convertFromRaw(rawData)
  } else if (text) {
    const contentBlock = htmlToDraft(text)
    contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    // contentState = convertFromHTML(convertFromHTMLConfig)(text)
  }
  const nextState = EditorState.createWithContent(contentState)
  /** 重要代码，提供给 draftjs-utils 内置解析数据结构 */
  extractInlineStyle(nextState)
  // console.log(JSON.stringify(this.getEditorRawData(nextState)))
  return nextState
}
export const getSelectionInfo = (editorState) => {
  if (!editorState) return {}
  const selectionState = editorState.getSelection()
  const anchorKey = selectionState.getAnchorKey()
  const start = selectionState.getStartOffset()
  const end = selectionState.getEndOffset()
  return {
    anchorKey,
    start,
    end,
  }
}

export const getSelectedText = (editorState) => {
  if (!editorState) return ""
  const { anchorKey, start, end } = getSelectionInfo(editorState)
  const currentContent = editorState.getCurrentContent()
  const currentContentBlock = currentContent.getBlockForKey(anchorKey)
  const selectedText = currentContentBlock.getText().slice(start, end)
  return selectedText
}

export const getEditorRawData = (editorState) => {
  if (!editorState) return null
  const contentState = editorState?.getCurrentContent()
  const rawData = convertToRaw(contentState)
  return rawData
}

export const htmlTagFilter = (tag: string) => {
  const res = tag.replace(/<[^>]*>?/gm, "")
  return res
}

/**
 * 获取保存的富文本的数据
 */
export const getCommitData = (editorState, values) => {
  const { fontFamily } = values
  if (editorState) {
    const html = convertToHTML(convert2HTMLConfig)
    const contentState = editorState.getCurrentContent()
    let textContent = html(contentState)

    // textContent = textContent.replace(
    //   /(?<=<(?<tag>\w+)(?: [^><]+)*>[^><]*)( +)(?=[^><]*<\/\k<tag>>)/g,
    //   (a) => "&nbsp;".repeat(a.length)
    // )
    // 将空格转义
    textContent = textContent.replace(/\s{2,}/g, (a) => {
      return "&nbsp;".repeat(a.length)
    })

    const rawData = getEditorRawData(editorState)

    /** 添加空白内容 */
    const planText = textContent.replace(/<[^>]*>?/gm, "")
    return {
      text: textContent,
      planText,
      textCrop: {
        content: planText,
        fontFamily,
      },
      // 存储原始数据，提供给移动端解析
      rawData,
    }
  }
}

export const selectAllState = (editorState: EditorState): EditorState => {
  const currentContent = editorState.getCurrentContent()
  const firstBlock = currentContent.getBlockMap().first()
  const lastBlock = currentContent.getBlockMap().last()
  const firstBlockKey = firstBlock.getKey()
  const lastBlockKey = lastBlock.getKey()
  const lengthOfLastBlock = lastBlock.getLength()

  const selection = new SelectionState({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: lastBlockKey,
    focusOffset: lengthOfLastBlock,
  })

  const nextState = EditorState.forceSelection(editorState, selection)
  return nextState
}

export const moveSelectionToEndState = (editorState: EditorState) => {
  const content = editorState.getCurrentContent()
  const blockMap = content.getBlockMap()

  const key = blockMap.last().getKey()
  const length = blockMap.last().getLength()

  // On Chrome and Safari, calling focus on contenteditable focuses the
  // cursor at the first character. This is something you don't expect when
  // you're clicking on an input element but not directly on a character.
  // Put the cursor back where it was before the blur.
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  })
  // return EditorState.forceSelection(editorState, selection)
  const nextState = EditorState.forceSelection(editorState, selection)

  return nextState
}

export const round = (input) => +(input || 0).toFixed(5)

export function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement("canvas"))
  const context = canvas.getContext("2d")
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

/** 获取移动设备屏幕宽度 */
const getMobileWidth = () => {
  return Math.min(document.documentElement.clientWidth, 750)
}
export const getWorksPadding = (str, scale) => {
  let scaleRate = getMobileWidth() / 375
  if (isPc()) {
    scaleRate = 1
  }
  const padding = String(str || "")
  const paddingList = padding?.split(" ") || []
  /** 根据屏幕宽度和375的比例缩放，然后返回padding */
  const getPadding = (val) => {
    return `${+val * scaleRate}px`
  }
  paddingList.forEach((item, index) => {
    paddingList[index] = getPadding(item.replace(/px/gi, ""))
  })
  return paddingList.join(" ")
}
